import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 420px;
  align-items: center;
`;

export const Title = styled.div`
  font-size: var(--font-l);
  text-align: left;
`;

export const Blurb = styled.div`
  width: 400px;
`;

export const Img = styled.img`
    width: 400px;
`;

export const Butt = styled.div`
  width: 200px;
  height: 80px;
  background: black;
  color: white;
  border-radius: 50%;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  padding-top: 10px;
`;

