import React from 'react';
import { useSelector } from 'react-redux';
import './headerProfile.css';
import { colors } from 'style/colors';
import TenantImage from 'components/tenantImage/TenantImage';
import { selectProfile } from "slices/profile-slice";
import Auth from "../../lib/auth";

interface HeaderProfileProps {
  auth: Auth;
}

function HeaderProfile(props:HeaderProfileProps) {
  const profile = useSelector(selectProfile);
  if(!profile) {
    return (<></>);
  }
  let name = profile ? profile.name : '';
  if(name === '__no_name__')
    name = profile.email;

  const databaseUser = profile.userDatabaseConnection;
  const enableLogoutSso = !profile.ssoLogoutDisabled;

  return (
    <>
      <div className="dropdown" style={{cursor:'pointer'}}>
        <div style={{display: 'flex'}}>
          <div style={{padding: '5px', fontSize:'small', display:'flex', alignItems:'center'}}>
            {name}
          </div>
          <div style={{padding: '5px', fontSize:'small', color: colors.green}}>
            <TenantImage tenantId={profile.tenantId} width="32px" />
          </div>
        </div>
        <div className="dropdown-content">
          { databaseUser && ( <div onClick={() => props.auth.changePassword(false)}>Change Password</div> ) }
          { databaseUser && ( <div onClick={() => props.auth.logout()}>Logout</div> ) }
          { !databaseUser && ( <div onClick={() => props.auth.switchUser()}>Logout</div> ) }
          { (!databaseUser && enableLogoutSso) && ( <div onClick={() => props.auth.logout()}>Logout SSO</div> ) }
        </div>
      </div>
    </>
  );
}

export default HeaderProfile;
