import urlJoin from 'url-join';
import config from './config';

export class ApiError {
  error?: string;
  errorDescription?: string;
  status: number;

  constructor(status: number, json?: any) {
    this.status = status;
    if (!json)
      return;
    this.error = json.error;
    this.errorDescription = json.errorDescription;
  }

  log(): void {
    let message = `Error (${this.status}): ${this.error}`;
    if (this.errorDescription)
      message += ` - ${this.errorDescription}`;
    console.log(message);
  }
}

export interface ApiResponse<T> {
  error?: ApiError;
  payload?: T;
}

export async function getRequest<T>(route: string): Promise<ApiResponse<T>> {
  const path = urlJoin(config.baseUrl, 'api', route);
  const result = await fetch(path, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });

  if (!result.ok) {
    if(result.status === 404)
      return { error: new ApiError(result.status, undefined)};

    const errorJson = await result.json();
    let error = new ApiError(result.status, errorJson);
    return {
      error: error,
    };
  }

  const resultParsed = (await result.json()) as T;
  return {
    payload: resultParsed,
  };
}
