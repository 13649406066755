import React from 'react';
import * as s from './marketing-tile';
import {Link} from "@material-ui/core";

export function MarketingTile() {
    return <s.Container>
        <s.Title>Enjoying Your GovInvest Experience?</s.Title>
        <s.Blurb>If you have friends or colleagues who you know would benefit from our solutions, refer them to our team
            and receive a GovInvest t-shirt. We’d love to invite them to join our community and experience the same results.
        </s.Blurb>
        <s.Img src="/friends-t-shirt.jpg" alt="T-Shirt with caption: Friends Don't Let Friends Forecast with Excel" />
        <a href="https://forms.office.com/r/jjZHbPrK83" target="_blank">
            <s.Butt>
                Refer a Friend Now!
            </s.Butt>
        </a>
    </s.Container>;
}
export default MarketingTile;