const reporter = (store:any) => (next:any) => (action:any) => {
  console.group(action.type);
  console.log('%c prev state', 'color: gray', store.getState());
  console.log('%c action', 'color: blue', action);
  const returnValue = next(action);
  console.log('%c next state', 'color: green', store.getState());
  console.groupEnd();
  return returnValue;
};

export default reporter;
