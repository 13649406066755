import { configureStore } from '@reduxjs/toolkit'
import reduxReporter from './lib/redux-reporter';
import profileSlice from './slices/profile-slice';
import appEnvironmentsSlice from './slices/app-environments-slice';

export const store = configureStore({
  reducer: {
    profile: profileSlice,
    appEnvironments: appEnvironmentsSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(reduxReporter),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch