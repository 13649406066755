import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';

export interface Profile {
  userId: string;
  email: string;
  name: string;
  tenantId: string;
  role: string;
  requiresPasswordReset: string;
  ssoLogoutDisabled: string;
  scope: Array<String>;
  clientId: string;
  userDatabaseConnection: boolean;
}

interface ProfileState {
  profile?: Profile;
}

const initialState: ProfileState = {
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    profileAvailable: (state, action: PayloadAction<Profile>) => {
      state.profile = action.payload;
    },
  },
});

export const {
  profileAvailable,
} = profileSlice.actions;

export const selectProfile = (state: RootState) => state.profile.profile;

export default profileSlice.reducer;
