import React from 'react';
import urlJoin from 'url-join';
import config from 'lib/config';

function TenantImage(props:any) {
  const { tenantId, width } = props;
  const baseUrl = config.iumBaseUrl;
  const uri = urlJoin(baseUrl, `api/tenant-img/${tenantId}`);
  return ( <img style={{width:width}} src={uri} alt="Tenant Logo" loading="lazy" />);
}
export default TenantImage;