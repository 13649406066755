import styled from 'styled-components';

export const SectionContainer = styled.section`
  border: solid 0.5px #e0e0e0;
  border-radius: 10px;
`;

export const ModulesContainer = styled.div`
  padding: 15px;
`;

export const ListContainer = styled.div`
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const ContainerTitle = styled.h2`
  font-size: var(--font-l);
  margin: 0 0 10px 0;
`;

export const LinksList = styled.div``;

export const LinkGroup = styled.div`
  font-size: var(--font-m);
  padding: 4px 0;
`;

export const Link = styled.a`
  color: var(--color-green);
  &:hover {
    color: var(--color-green-d);
  }
`;
