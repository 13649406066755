import React from 'react';
import HeaderProfile from "../headerProfile/HeaderProfile";
import SysAdminMenu from "../sysAdminMenu/SysAdminMenu";
import Auth from "../../lib/auth";
import {Link} from "react-router-dom";
import { colors } from 'style/colors';
import {useSelector} from "react-redux";
import {Profile, selectProfile} from "slices/profile-slice";
import * as constants from 'lib/constants';

interface HeaderProps {
  auth: Auth;
}

function Header(props:HeaderProps) {
  const profile = useSelector(selectProfile) as Profile;
  if(!profile)
    return <></>;
  const isSysAdmin = profile.role === constants.roles.SysAdmin;
  const itemStyle : React.CSSProperties = {
    padding:'3px',
    display: 'flex',
    flexDirection:'column',
    justifyContent: 'center',
  };
  return (
    <>
      <div style={{marginLeft: '5px', marginRight: '5px', padding:'5px'}} className="header-container">
        <div className="logo-title-container">
          <div style={itemStyle}>
            <img style={{height:'40px'}} src="/govinvest_logo_no_subtitle_small.png" alt="GovInvest, Inc. Logo"/>
          </div>
          <div style={itemStyle}>
            <div style={{fontWeight:1.2, fontSize:"24px", marginLeft: '10px'}}>
              <Link to="/app">
                <div style={{color:colors.black}}>
                  Home
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div style={{display:'flex'}}>
          {
            isSysAdmin && (
              <div style={itemStyle}>
                <SysAdminMenu />
              </div>
            )
          }
          <div style={itemStyle}>
            <HeaderProfile auth={props.auth} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
