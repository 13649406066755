interface Config {
  baseUrl: string;
  iumBaseUrl: string;
}

const readConfig = ():Config => {
  const env = (window as any).env;
  return {
    baseUrl: env.baseUrl as string,
    iumBaseUrl: env.iumBaseUrl as string,
  }
}
const config = readConfig();
export default config;