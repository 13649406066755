import React from 'react';
import MyButton from '../Button';
import Auth from '../../lib/auth';

function LoginFailed(props) {

  function convertErrorToTitle(str) {
    return str.replaceAll('_', ' ').replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  function redirectHome() {
    const auth = new Auth();
    // noinspection JSIgnoredPromiseFromCall
      auth.logoutClearStorageAndGoHome();
  }

  const {error, errorDescription} = props;
  return (
    <div style={{margin:'30px', display:'flex', flexDirection:'column', alignItems:'center'}}>
      <div><img style={{height:'50px', marginBottom:'20px'}} src="/govinvest_logo_no_subtitle_small.png" alt="GovInvest Logo"/></div>
      <div style={{fontSize:'2em', marginBottom:'20px'}}>Login Failed</div>
      <div style={{marginBottom:'10px'}}><label style={{fontWeight:'bold'}}>Error: </label>{convertErrorToTitle(error)}</div>
      <div style={{marginBottom: '20px'}}><label style={{fontWeight:'bold'}}>Description: </label>{errorDescription}</div>
      <div onClick={redirectHome} >
        <MyButton>Back to Login</MyButton>
      </div>
    </div>
    );
};

export default LoginFailed;