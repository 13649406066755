import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import {getRequest} from "../lib/api-common";
import {Dispatch} from "react";

export interface AppEnvironment {
  appEnvId: string,
  title: string,
  moduleId: string,
  type: string,
  link: string,
}

interface GetAppEnvironmentsResponse {
  appEnvironments: Array<AppEnvironment>;
}

interface AppEnvironmentsState {
  list: Array<AppEnvironment>;
}

const initialState: AppEnvironmentsState = {
  list: [],
};

export const fetchAppEnvironments = async (dispatch: Dispatch<any>) => {
  const result = await getRequest<Array<AppEnvironment>>('appenvironments');
  if(result.error) {
    result.error.log();
    throw Error('Failed to fetch app environments');
  }
  if(!result.payload)
    throw Error('Cannot retrieve app environments: payload null or undefined')
  dispatch(appEnvironmentsRetrieved(result.payload));
};

export const appEnvironmentsSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    appEnvironmentsRetrieved: (state, action: PayloadAction<Array<AppEnvironment>>) => {
      state.list = action.payload;
    },
  },
});

export const {
  appEnvironmentsRetrieved,
} = appEnvironmentsSlice.actions;

export const selectAppEnvironments = (state: RootState) => state.appEnvironments.list;

export default appEnvironmentsSlice.reducer;
