export const config = {
  storagePrefix: 'lcm',
  clientId: window.env.clientId,
  iumBaseUrl: window.env.iumBaseUrl,
  appBaseUrl: window.env.baseUrl,
  appEnvId: window.env.appEnvId,
  envId: window.env.envId,
  appEnvInstance: window.env.envInstance,
  refreshInterval: 300000,
};
export default config;