import React from 'react';
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import {Outlet} from "react-router-dom";
import Auth from "lib/auth";

interface MainPageProps {
  auth: Auth;
}

function MainPage(props:MainPageProps) {
  return (
    <div id="main-page-container">
      <div id="content-wrap">
        <div id="header-row" className="row">
          <Header auth={props.auth} />
        </div>
        <div className="row">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MainPage;