import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components';
import {AppEnvironment, fetchAppEnvironments, selectAppEnvironments} from "slices/app-environments-slice";
import Spinner from "../Spinner";
import MarketingTile from 'components/marketingTile/MarketingTile';
import RecordingsTile from 'components/recordingsTile/RecordingsTile';

function HomePage() {
  const dispatch = useDispatch();
  const appEnvironments = useSelector(selectAppEnvironments);

  useEffect(() => {
    fetchAppEnvironments(dispatch).catch(console.error);
  }, []);

  if(appEnvironments.length === 0)
    return <Spinner />;

  const appTilesSection = (title:string, type:string, appEnvs:Array<AppEnvironment>) => {
    const filtered = appEnvironments.filter(x => x.type === type);
    if(filtered.length === 0)
      return <></>;
    return(
      <AppTilesSection>
        <TitleSection>
          <Title>{title}</Title>
        </TitleSection>
        <AppTileSection>
        {
          filtered.map(a =>
            <a href={a.link} key={a.appEnvId}>
              <AppEnvTile>
                <TileTitle>
                  {a.title}
                </TileTitle>
              </AppEnvTile>
            </a>
          )
        }
        </AppTileSection>
      </AppTilesSection>
    );
  };

  return <Main>
    <HomeContainer>
      <HomeLeft>
      { appTilesSection("My Modules", "customer", appEnvironments) }
      { appTilesSection("Staff Internal", "internal", appEnvironments) }
      { appTilesSection("Development", "dev", appEnvironments) }
      { appTilesSection("Pre-Prod", "preprod", appEnvironments) }
      </HomeLeft>
      <HomeRight>
        <MarketingTile />
        <RecordingsTile/>
      </HomeRight>
    </HomeContainer>

    <BottomLinks>
      <a href="https://govinvest.com/contact-us">
        Contact Us
      </a>
    </BottomLinks>
  </Main>;
}
export default HomePage;

const Main = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const HomeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 60px;
`;

const HomeLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const HomeRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

const AppTilesSection = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 0.5px #e0e0e0;
  padding: 10px 25px 35px;
  border-radius: 10px;
  gap: 12px;
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  padding: 10px 0 0;
`;

const Title = styled.div`
  font-size: var(--font-xl);
`;

const AppTileSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  padding-top:10px;
`;

const AppEnvTile = styled.div`
  width: 350px;
  height: 130px;
  background: #fafafa;
  padding: 20px;
  border: solid 0.5px #e0e0e0;
  border-top: 3px solid #4cb887;
  display: flex;
  align-items: center;
  box-shadow: 2px 2px 8px #eaedf0;
  color: var(--color-black);

  &:hover {
    color: #4cb887;
  }
`;

const TileTitle = styled.div`
  font-size: var(--font-l);
`;

const BottomLinks = styled.div`
  display: flex;
  justify-content: center;
`;
