import * as S from './recordings-tile';

export default function RecordingsTile() {
  return (
    <S.SectionContainer>
      <S.ModulesContainer>
        <S.ListContainer>
          <S.ContainerTitle>Labor Costing Recording Links</S.ContainerTitle>
          <S.LinksList>
            <S.LinkGroup>
              <S.Link
                href='https://govinvest.wistia.com/medias/500oa8cag9'
                target='_blank'
                rel='noreferrer'>
                Proposals &amp; Scenarios
              </S.Link>
            </S.LinkGroup>
            <S.LinkGroup>
              <S.Link
                href='https://govinvest.wistia.com/medias/c33qxg5sby'
                target='_blank'
                rel='noreferrer'>
                Setting Up Partial Allocations &amp; Chart of Accounts
              </S.Link>
            </S.LinkGroup>
            <S.LinkGroup>
              <S.Link
                href='https://govinvest.wistia.com/medias/wqfmk5zkei'
                target='_blank'
                rel='noreferrer'>
                Usage of Tables Feature
              </S.Link>
            </S.LinkGroup>
            <S.LinkGroup>
              <S.Link
                href='https://govinvest.wistia.com/medias/chjcq2vpy8'
                target='_blank'
                rel='noreferrer'>
                Annual Census File Update
              </S.Link>
            </S.LinkGroup>
          </S.LinksList>
        </S.ListContainer>
        <S.ListContainer>
          <S.ContainerTitle>Pension Recording Links</S.ContainerTitle>
          <S.LinksList>
            <S.LinkGroup>
              <S.Link
                href='https://govinvest.wistia.com/medias/dr2lc80rk6'
                target='_blank'
                rel='noreferrer'>
                Additional Discretionary Payments &amp; Fresh Starts
              </S.Link>
            </S.LinkGroup>
            <S.LinkGroup>
              <S.Link
                href='https://govinvest.wistia.com/medias/ibja3whavi'
                target='_blank'
                rel='noreferrer'>
                Building Scenarios
              </S.Link>
            </S.LinkGroup>
          </S.LinksList>
        </S.ListContainer>
      </S.ModulesContainer>
    </S.SectionContainer>
  );
}
